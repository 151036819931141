import Dropzone from 'dropzone';
var defaultOptions = {
    autoProcessQueue: false,
    parallelUploads: 1,
    uploadMultiple: false,
    maxFiles: 1,
    paramName: 'import_file',
    clickable: '.upload-container--link',
    previewsContainer: '.dropzone-previews',
    maxFilesize: 10,
};
function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
}
export function createFileUploader(selector, options) {
    Dropzone.autoDiscover = false;
    var element = document.querySelector(selector);
    if (!element) {
        throw new Error('Element not found');
    }
    var submitButton = element.querySelector('[type="submit"]');
    if (!submitButton) {
        throw new Error('Submit button not found');
    }
    var dropTarget = document.getElementById('drop-target');
    if (!dropTarget) {
        dropTarget = document.createElement('div');
        dropTarget.id = 'drop-target';
        dropTarget.className = 'tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-[10000] !tw-hidden';
        var innerDiv = document.createElement('div');
        innerDiv.className = 'tw-absolute tw-top-[7px] tw-right-[7px] tw-bottom-[7px] tw-left-[7px] tw-bg-[#EAEAEA7F] tw-border-[5px] tw-border-dashed tw-border-[#BBBBBBFF] tw-pointer-events-none';
        dropTarget.appendChild(innerDiv);
        document.body.appendChild(dropTarget);
    }
    options = Object.assign(defaultOptions, options);
    document.body.addEventListener('dragover', preventDefaults);
    document.body.addEventListener('dragenter', function (e) {
        preventDefaults(e);
        var modal = options.modalSelector ? document.querySelector(options.modalSelector) : null;
        if (modal) {
            document.dispatchEvent(new CustomEvent('inv:modal-show', { detail: { modal: modal } }));
        }
        dropTarget.classList.remove('!tw-hidden');
    });
    dropTarget.addEventListener('dragleave', function (e) {
        preventDefaults(e);
        dropTarget.classList.add('!tw-hidden');
    });
    var dropZone = initDropzone(element, options);
    submitButton.removeEventListener('click', handleSubmit);
    submitButton.addEventListener('click', handleSubmit);
    dropZone.on('processing', function () {
        submitButton.value = 'Качва се...';
        submitButton.classList.add('!tw-text-gray-500', 'hover:!tw-bg-[#eee]');
        submitButton.disabled = true;
    });
    dropZone.on('dragenter', function (e) {
        preventDefaults(e);
        dropTarget.classList.remove('!tw-hidden');
    });
    document.body.addEventListener('drop', function (event) {
        var _a;
        event.preventDefault();
        dropTarget.classList.add('!tw-hidden');
        var files = (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.files;
        if (files === null || files === void 0 ? void 0 : files.length) {
            Array.from(files).forEach(function (file) {
                dropZone.addFile(file);
            });
        }
    });
    function handleSubmit(e) {
        if (!dropZone.files.length) {
            alert('Моля, изберете файл за импортиране');
            e.preventDefault();
            return false;
        }
        dropZone.processQueue();
    }
    return dropZone;
}
function initDropzone(element, options) {
    var dropZone = new Dropzone(element, options);
    dropZone.on('maxfilesexceeded', function (file) {
        dropZone.removeAllFiles();
        dropZone.addFile(file);
    });
    dropZone.on('addedfile', function (file) {
        var _a;
        var maxFileSizeMB = ((_a = options.maxFilesize) !== null && _a !== void 0 ? _a : 10) * 1024 * 1024;
        if (file.size > maxFileSizeMB) {
            var body = 'Файлът надвишава максималния допустим размер от ' + maxFileSizeMB + " MB.";
            alert(body);
            dropZone.removeFile(file);
            return;
        }
        var fileNameElement = document.querySelector('.drop-zone__file-name');
        if (fileNameElement) {
            fileNameElement.style.paddingTop = '10px';
        }
        var removeFileButton = file.previewTemplate.querySelector('.remove-file');
        if (removeFileButton) {
            removeFileButton.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                if (confirm("Да премахна ли файла за качване?")) {
                    dropZone.removeFile(file);
                }
            });
        }
    });
    dropZone.on('success', function (file, response) {
        window.location = response.redirectTo;
    });
    dropZone.on('error', function (file, response) {
        if (response.redirectTo) {
            window.location = response.redirectTo;
        }
    });
    return dropZone;
}
