var defaultOptions = {
    position: 'top',
    arrowPosition: 'left',
};
export function createTitleTooltip(selector, options) {
    var _a;
    var element = document.querySelector(selector);
    if (!element) {
        throw new Error('Element not found');
    }
    options = Object.assign(defaultOptions, options);
    if (!options.content) {
        throw new Error('Tooltip Error: You must provide content for Tooltip to display');
    }
    var content = document.createElement('span');
    content.innerHTML = options.content;
    content.classList.add('tooltip-text', '!tw-p-[10px]', '!tw-font-normal', '!tw-w-max');
    var arrowSelector = ['top-arrow'];
    if (options.position === 'top') {
        content.classList.add('top-tooltip');
        content.style.bottom = "".concat(element.offsetHeight + 8, "px");
        arrowSelector = ['bottom-arrow', '!tw-border-t-8'];
    }
    else {
        content.classList.add('bottom-tooltip');
        content.style.top = "".concat(element.offsetHeight + 8, "px");
    }
    var arrow = document.createElement('span');
    (_a = arrow.classList).add.apply(_a, arrowSelector);
    content.appendChild(arrow);
    var tooltipOffsetX = 4;
    var arrowWidth = 12;
    switch (options.arrowPosition) {
        case 'left':
            content.classList.add('arrow-left');
            content.style.marginLeft = "-".concat(tooltipOffsetX, "px");
            arrow.style.left = "".concat((tooltipOffsetX - 1) + (element.offsetWidth / 2), "px");
            break;
        case 'right':
            content.classList.add('arrow-right');
            content.style.marginRight = "-".concat(tooltipOffsetX, "px");
            arrow.style.right = "".concat(((tooltipOffsetX - 1) - arrowWidth / 2) + (element.offsetWidth / 2), "px");
            arrow.style.left = 'unset';
            break;
        case 'center':
        default:
            break;
    }
    element.classList.add('tooltip');
    element.appendChild(content);
    var hoverTimer = null;
    element.addEventListener('mouseover', function (e) {
        hoverTimer = setTimeout(function () {
            element.querySelector('.tooltip-text').style.display = 'block';
        }, 1000);
    });
    element.addEventListener('mouseout', function () {
        if (hoverTimer) {
            clearTimeout(hoverTimer);
            hoverTimer = null;
        }
        element.querySelector('.tooltip-text').style.display = 'none';
    });
}
