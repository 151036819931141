import './index.css';
import * as Sentry from '@sentry/browser';
import * as htmx from 'htmx.org';
import Alpine from 'alpinejs';
import morph from '@alpinejs/morph';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import * as EmailValidator from 'email-validator';
import { createFileUploader } from './files/file-uploader';

import posthog from 'posthog-js';
import { createTitleTooltip } from "@app/lib/title-tooltip";

if (process.env.COLLECT_JS_ERRORS) {
    Sentry.init({
        release: `invbg@${process.env.GIT_VERSION}`,
        environment: process.env.ENVIRONMENT,
        dsn: process.env.SENTRY_DSN,
        ignoreErrors: [
            // Random plugins/extensions
            "top.GLOBALS",
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            "originalCreateNotification",
            "canvas.contentDocument",
            "MyApp_RemoveAllHighlights",
            "http://tt.epicplay.com",
            "Can't find variable: ZiteReader",
            "jigsaw is not defined",
            "ComboSearch is not defined",
            "http://loading.retry.widdit.com/",
            "atomicFindClose",
            // Facebook borked
            "fb_xd_fragment",
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            "bmi_SafeAddOnload",
            "EBCallBackMessageReceived",
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            "conduitPage",
            "Non-Error exception captured",
            "Non-Error promise rejection captured"
        ],
        allowUrls: [
            /^(https?:\/\/)?([a-zA-Z0-9-]+\.)?inv\.bg(\/.*)?$/i
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            /chat.inv.bg/i,
        ],
    })
}

function startPosthog() {
    if (process.env.ENVIRONMENT !== 'development') {
        posthog.init(process.env.POSTHOG_API_KEY,
            {
                api_host: 'https://eu.i.posthog.com',
                person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
            }
        )
    }
}

window.startPosthog = startPosthog
window.htmx = htmx;
window.Alpine = Alpine;
Alpine.plugin(morph);

Alpine.start();

window.isValidPhoneNumber = function (phoneNumber) {
    try {
        parsePhoneNumberWithError(phoneNumber, {
            defaultCountry: 'BG',
            extract: false
        });
    } catch (e) {
        return false;
    }

    return true;
}
window.isValidEmail = function (email) {
    try {
        return EmailValidator.validate(email);
    } catch (e) {
        return false;
    }
}
window.createFileUploader = createFileUploader;
window.createTitleTooltip = createTitleTooltip;
